
        import { createElement as _createElement } from 'react';
import { Fragment as _Fragment } from 'react';
import _map from 'lodash-es/map';
export default function singleVehicleGarageRT () {
    return _createElement('div', { 'className': 'garage-container' }, [this.changeButton(function () {
            return _createElement('div', { 'className': 'cm_changeButton' }, this.template === 'active' ? _createElement('div', {
                'className': 'active-container cmTemplate_active',
                'key': '34'
            }, _createElement('div', { 'className': 'garage-icon cm_icon_garage__background' }), ' ', window.Convermax.config?.selectYourVehicleTitle || 'Select Your Vehicle') : null, this.template === 'locked' ? _createElement('div', {
                'className': 'locked-container cmTemplate_locked',
                'key': '276'
            }, _createElement('span', { 'className': 'model' }, this.vehicle.Year, ' ', this.vehicle.Make, ' ', this.vehicle.Model), _createElement('span', { 'className': 'change' }, 'Change'), _createElement('span', { 'className': 'clear' }, [_createElement('svg', {
                    'xmlns': 'http://www.w3.org/2000/svg',
                    'className': 'cm_icon cm_icon-reset',
                    'height': '16px',
                    'viewBox': '0 0 16 16',
                    'key': '5310'
                }, _createElement('path', { 'd': 'M2.083,9H0.062H0v5l1.481-1.361C2.932,14.673,5.311,16,8,16c4.08,0,7.446-3.054,7.938-7h-2.021 c-0.476,2.838-2.944,5-5.917,5c-2.106,0-3.96-1.086-5.03-2.729L5.441,9H2.083z' }), _createElement('path', { 'd': 'M8,0C3.92,0,0.554,3.054,0.062,7h2.021C2.559,4.162,5.027,2,8,2c2.169,0,4.07,1.151,5.124,2.876 L11,7h2h0.917h2.021H16V2l-1.432,1.432C13.123,1.357,10.72,0,8,0z' }))])) : null);
        }, {
            widgetName: 'undefined',
            items: undefined
        })]);
}
        export const componentNames = ["cm:changeButton"]